import './distributiva_parques.scss';

import { Link, graphql } from 'gatsby';

import PropTypes from 'prop-types';
import React from 'react';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import Loki from '../src/components/Loki/lokipane';
import SEO from '../src/helpers/seo';
import Thor from '../src/components/Thor/Thor';
import Tyr from '../src/components/Tyr/Tyr';
import Hod from '../src/components/Hod/Hod';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Astrid from '../src/components/Astrid/Astrid';
import Gerd from '../src/components/Gerd/Gerd';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query DistributivaParques($locale: String!) {
    allParksHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allDistParquesMobileImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allDistParquesDiscoverCardsBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          Image
          altImage
          Text
          Title
          TitleURL
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allParksHeaderTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allRelatedParksPromotedSubBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          ctaText
          cta
          title
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt_image
        }
      }
    }
    allDistParquesBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          tag
          destination_url
          name
        }
      }
    }
    allParksSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allDistParquesSliderGallery(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt_image
        }
      }
    }
    bigLokiPics: allDistParquesSliderGallery(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt_image
        }
      }
    }
    allDistParquesRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class DistributivaParques extends React.Component {
  state = {
    showModal: false,
  };

  handleMap = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const {
      data,
      pageContext,
      pathContext: { locale, pageName },
    } = this.props;
    const parquesData = {
      promotions: data.allRelatedParksPromotedSubBlock.edges,
    };

    const thorData = {
      title: tt('¿Conoces los parques de PortAventura World?', locale),
      subtitle: tt(
        'Tres parques únicos con miles de emociones te esperan para sorprenderte. ¡No te los pierdas!',
        locale
      ),
      cards: [
        {
          Icon: 'pa',
          titleIcon: 'PORTAVENTURA PARK',
          subtitleIcon: tt(
            '43 atracciones, 35 tiendas, múltiples opciones culinarias y ¡nuevos espectáculos cada época del año!',
            locale
          ),
        },
        {
          Icon: 'ferrari',
          titleIcon: 'FERRARI LAND',
          subtitleIcon: tt(
            'Único parque en Europa dedicado a la escudería italiana, ¡para toda la familia!',
            locale
          ),
        },
        {
          Icon: 'caribe',
          titleIcon: 'CARIBE AQUATIC PARK',
          subtitleIcon: tt(
            'En verano, ven a divertirte en el parque acuático más refrescante de PortAventura World.',
            locale
          ),
        },
      ],
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', locale),
      cards: parquesData.promotions.map((parque) => ({
        image: parque.node.localImage.childImageSharp.fluid,
        altImage: parque.node.alt_image,
        title: parque.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: parque.node.cta,
          size: 'alone',
          color: 'white',
          ctaText: parque.node.ctaText,
        },
      })),
    };

    const heimdallData = {
      name: data.allParksHeaderBlock.edges[0].node.name,
      image: {
        url: data.allParksHeaderBlock.edges[0].node.localImage.childImageSharp.fluid,
      },
      imageMobile: {
        url: data.allDistParquesMobileImage.edges[0].node.localImage.childImageSharp.fluid,
      },
      map: 'true',
    };
    const prepareForTyr = () => ({
      title: 'Title',
      cards: data.allDistParquesDiscoverCardsBlock.edges.map((block) => ({
        MainImage: block.node.localImage.childImageSharp.fluid,
        Image: block.node.Image,
        altImage: block.node.altImage,
        Title: block.node.Title,
        TitleURL: block.node.button1.cta,
        Text: block.node.Text,
        button1: {
          cta: block.node.button1.cta,
          size: block.node.button1.size,
          color: block.node.button1.color,
          ctaText: block.node.button1.ctaText,
        },
        button2: {
          cta: block.node.button2.cta,
          size: block.node.button2.size,
          color: block.node.button2.color,
          ctaText: block.node.button2.ctaText,
        },
      })),
    });

    const prepareForLoki = () => ({
      title: tt('GALERIA DE FOTOS', locale),
      arrayImg: data.allDistParquesSliderGallery.edges
        .map((block) => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.alt_image,
        }))
        .filter((item) => item.img !== null),
      bigArrayImg: data.bigLokiPics.edges
        .map((block) => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.alt_image,
        }))
        .filter((item) => item.img !== null),
    });

    const titleH3 = {
      title: data.allParksHeaderTitleBlock.edges[0].node.name,
    };
    return (
      <Layout layoutFromOld  locale={locale} pageName={pageName}>
        <SEO
          title={data.allParksSeoData.edges[0].node._0.title}
          description={data.allParksSeoData.edges[0].node._1.description}
          pathname={pageContext.url}
          lang={pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} handleMap={this.handleMap} />
          <div className="general-index">
            <div className="parks-content">
              <div className="hotel-content-grey">
                <Astrid
                  pageContext={this.props.pageContext}
                  handleMap={this.handleMap}
                  modal={this.state.showModal}
                />
                <NeoAesir
                  locale={locale}
                  pageName={pageName}
                  title={data.allDistParquesBreadCrumbBlock.edges[0].node.name}
                />
                <Hod
                  data={this.props.data.allDistParquesRichData.edges[0].node.rich_markup}
                  url={this.props.pageContext.url}
                  img={this.props.data.allParksHeaderBlock.edges[0].node.image}
                />
                <Thor data={thorData} />
              </div>
              <div className="experience-content">
                <H3 data={titleH3} />
                <Tyr data={prepareForTyr()} />
              </div>
              <H4 title={prepareForLoki().title} />
              <Loki data={prepareForLoki()} />
              <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
              <div className="promotion-content">
                <H4 title={freyaData.title} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
DistributivaParques.propTypes = {
  ourParksData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default DistributivaParques;
